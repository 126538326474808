import React, { createContext, useContext, useReducer, ReactElement, useCallback } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export type LoadingContextState = {
  isBackdropVisible: boolean;
  isLoading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
  showBackdrop: () => void;
  hideBackdrop: () => void;
  loadingProcessCount: number;
};

export type LoadingProviderProps = {
  children: ReactElement;
};

type Action =
  | { type: 'SHOW_LOADING' }
  | { type: 'HIDE_LOADING' }
  | { type: 'SHOW_BACKDROP' }
  | { type: 'HIDE_BACKDROP' };

const stub = (): never => {
  throw new Error('Wrap your component in a LoadingProvider');
};

const defaultState: LoadingContextState = {
  isBackdropVisible: false,
  isLoading: false,
  showLoading: stub,
  hideLoading: stub,
  showBackdrop: stub,
  hideBackdrop: stub,
  loadingProcessCount: 0,
};

const reducer = (state: LoadingContextState, action: Action) => {
  switch (action.type) {
    case 'SHOW_LOADING':
      return {
        ...state,
        loadingProcessCount: state.loadingProcessCount + 1,
        isLoading: state.loadingProcessCount + 1 > 0,
      };
    case 'HIDE_LOADING':
      return {
        ...state,
        loadingProcessCount: state.loadingProcessCount - 1,
        isLoading: state.loadingProcessCount - 1 > 0,
      };
    case 'SHOW_BACKDROP':
      return {
        ...state,
        isBackdropVisible: true,
      };
    case 'HIDE_BACKDROP':
      return {
        ...state,
        isBackdropVisible: false,
      };
    default:
      return {
        ...state,
        loadingProcessCount: 0,
        isBackdropVisible: false,
        isLoading: false,
      };
  }
};

const useStyles = makeStyles(() => ({
  backdrop: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.15)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9001,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
}));

export const LoadingContext = createContext<LoadingContextState>(defaultState);

export const LoadingProvider = ({ children }: LoadingProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const classes = useStyles();

  const showLoading = useCallback(() => {
    dispatch({ type: 'SHOW_LOADING' });
  }, []);

  const hideLoading = useCallback(() => {
    dispatch({ type: 'HIDE_LOADING' });
  }, []);

  const showBackdrop = useCallback(() => {
    dispatch({ type: 'SHOW_BACKDROP' });
  }, []);

  const hideBackdrop = useCallback(() => {
    dispatch({ type: 'HIDE_BACKDROP' });
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        ...state,
        showLoading,
        hideLoading,
        showBackdrop,
        hideBackdrop,
      }}
    >
      <div className={clsx(classes.backdrop, { [classes.hidden]: !state.isLoading })} tabIndex={-1}>
        <CircularProgress color="primary" />
      </div>
      <div
        className={clsx(classes.backdrop, { [classes.hidden]: !state.isBackdropVisible })}
        tabIndex={-1}
      />
      {children}
    </LoadingContext.Provider>
  );
};

/* eslint-disable-next-line */
export const useLoading = (): LoadingContextState => useContext<LoadingContextState>(LoadingContext);
